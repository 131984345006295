import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css'
import List from './List';
import Bulk from './Bulk';
import Single from './Single';
import ErrorPage from "./404";
import axios from 'axios';

function Main({ auth }) {
    const toggleMenu = () => {
        document.querySelector(".horizontal-menu .bottom-navbar").classList.toggle("header-toggled");
    }
    const logout = () => {
        axios.post('/logout').then((ok) => {
            if (ok.data !== 'err') {
                window.location.assign("/")
            } else {
                window.location.reload()
            }
        })
    }
    return (
        <BrowserRouter>
            <div className='main-wrapper'>
                <div className="horizontal-menu">
                    <nav className="navbar top-navbar">
                        <div className="container">
                            <div className="navbar-content">
                                <a href="/" className="navbar-brand">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-gitlab"><path d="M22.65 14.39L12 22.13 1.35 14.39a.84.84 0 0 1-.3-.94l1.22-3.78 2.44-7.51A.42.42 0 0 1 4.82 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.49h8.1l2.44-7.51A.42.42 0 0 1 18.6 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.51L23 13.45a.84.84 0 0 1-.35.94z" /></svg>
                                    &nbsp; Hzho<span> B-Mail</span>
                                </a>
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <a href="/" className="text-white ms-0" onClick={() => { logout(); return false; }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" /><polyline points="16 17 21 12 16 7" /><line x1={21} y1={12} x2={9} y2={12} /></svg>
                                        </a>
                                    </li>
                                </ul>
                                <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="horizontal-menu-toggle" onClick={() => { toggleMenu() }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-menu"><line x1={3} y1={12} x2={21} y2={12} /><line x1={3} y1={6} x2={21} y2={6} /><line x1={3} y1={18} x2={21} y2={18} /></svg>
                                </button>
                            </div>
                        </div>
                    </nav>
                    <nav className="bottom-navbar">
                        <div className="container">
                            <ul className="nav page-navigation">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-list"><line x1={8} y1={6} x2={21} y2={6} /><line x1={8} y1={12} x2={21} y2={12} /><line x1={8} y1={18} x2={21} y2={18} /><line x1={3} y1={6} x2="3.01" y2={6} /><line x1={3} y1={12} x2="3.01" y2={12} /><line x1={3} y1={18} x2="3.01" y2={18} /></svg>
                                        <span className="menu-title">Mail List</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/single">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-plus"><line x1={12} y1={5} x2={12} y2={19} /><line x1={5} y1={12} x2={19} y2={12} /></svg>
                                        <span className="menu-title">Create Single Email</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/bulk">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-cpu"><rect x={4} y={4} width={16} height={16} rx={2} ry={2} /><rect x={9} y={9} width={6} height={6} /><line x1={9} y1={1} x2={9} y2={4} /><line x1={15} y1={1} x2={15} y2={4} /><line x1={9} y1={20} x2={9} y2={23} /><line x1={15} y1={20} x2={15} y2={23} /><line x1={20} y1={9} x2={23} y2={9} /><line x1={20} y1={14} x2={23} y2={14} /><line x1={1} y1={9} x2={4} y2={9} /><line x1={1} y1={14} x2={4} y2={14} /></svg>
                                        <span className="menu-title">Generate Bulk Email</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div >
                <div className="page-wrapper">
                    <div className="page-content">
                        <Routes>
                            <Route path="/" element={<List auth={auth} />} errorElement={<ErrorPage />} />
                            <Route path="/bulk" element={<Bulk auth={auth} />} errorElement={<ErrorPage />} />
                            <Route path="/single" element={<Single auth={auth} />} errorElement={<ErrorPage />} />
                        </Routes>
                    </div>
                    <footer className="footer border-top">
                        <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between py-3 small">
                            <p className="text-muted mb-1 mb-md-0">Copyright © 2024 <a href="/" target="_blank">hazho.com</a>.</p>
                            <p className="text-muted">Coded By: <a href="https://samserver.dev/" target="_blank">SamServer.dev</a></p>
                        </div>
                    </footer>
                </div>
            </div >
        </BrowserRouter >
    );
}

export default Main;