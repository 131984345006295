import { toast } from 'react-toastify';
import axios from 'axios';
import { useEffect } from 'react';

const Login = ({ auth }) => {
    useEffect(() => {
        if (auth) {
            window.location.assign("/")
        }
    }, [auth])
    return (
        <div className='main-wrapper'>
            <div className="page-wrapper full-page">
                <div className="page-content d-flex align-items-center justify-content-center">
                    <div className="row w-100 mx-0 auth-page">
                        <div className="col-md-8 col-xl-6 mx-auto">
                            <div className="card">
                                <div className="row">
                                    <div className="col-md-4 pe-md-0">
                                        <div className="py-4 px-4 mt-5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={245} height={245} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-gitlab"><path d="M22.65 14.39L12 22.13 1.35 14.39a.84.84 0 0 1-.3-.94l1.22-3.78 2.44-7.51A.42.42 0 0 1 4.82 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.49h8.1l2.44-7.51A.42.42 0 0 1 18.6 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.51L23 13.45a.84.84 0 0 1-.35.94z" /></svg>
                                        </div>
                                    </div>
                                    <div className="col-md-8 ps-md-0">
                                        <div className="auth-form-wrapper px-4 py-5">
                                            <a href="/" className="noble-ui-logo d-block mb-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-gitlab"><path d="M22.65 14.39L12 22.13 1.35 14.39a.84.84 0 0 1-.3-.94l1.22-3.78 2.44-7.51A.42.42 0 0 1 4.82 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.49h8.1l2.44-7.51A.42.42 0 0 1 18.6 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.51L23 13.45a.84.84 0 0 1-.35.94z" /></svg>
                                                &nbsp; Hazho<span>B-Mail</span>
                                            </a>
                                            <h5 className="text-muted fw-normal mb-4">Welcome back! Log in to your account.</h5>
                                            <form className="forms-sample" onSubmit={(e) => {
                                                e.preventDefault();
                                                toast.info("login...")
                                                const formData = new FormData(e.target);
                                                const data = Object.fromEntries(formData.entries());
                                                axios.post("/login", data).then((res) => {
                                                    if (res.data === "err") {
                                                        toast.error(res.data)
                                                    } else {
                                                        window.location.assign("/")
                                                    }
                                                })
                                            }}>
                                                <div className="mb-3">
                                                    <label htmlFor="username" className="form-label">Username</label>
                                                    <input type="text" className="form-control" name="username" placeholder="Username" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="password" className="form-label">Password</label>
                                                    <input type="password" className="form-control" name="password" autoComplete="current-password" placeholder="Password" />
                                                </div>
                                                <div>
                                                    <button type="submit" className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0"> Login  </button>
                                                </div>
                                                <a href="https://wa.me/+9647514584888?text=Hi,%20I%20am%20customer%20from%20samserver.dev" target='_blank' rel="noreferrer" className="d-block mt-3 text-muted">Fortgot Password? PM me.</a>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;