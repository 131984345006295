import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from 'jquery';
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const List = () => {
    const [mails, setMails] = useState([]);
    
    const GetMails = async () => {
        const res = await axios.get('/mails');
        setMails(res.data);
        if (res.data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable();
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    }

    const generator = () => {
        const length = 16;
        const charset = "a!B@c#D$e%F^g&H*i(G)k_L+m=N-o[P]q{R}s~T}u|VwXyZ0123456789";
        let retVal = "";
        for (let i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    useEffect(() => {
        GetMails();
    }, []);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            toast.success('Copied to clipboard!');
        }).catch(err => {
            toast.error('Failed to copy!');
        });
    };

    return (
        <div className="list">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header d-sm-flex d-block border-0 pb-3">
                            <div>
                                <h5 className="fs-20 mb-1 text-black">Mail List</h5>
                                <span className="fs-12"> List of all the mails in the system</span>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table dt-tbl" style={{ display: "none" }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>E-Mail</th>
                                            <th>Password</th>
                                            <th>Domain</th>
                                            <th>Quota</th>
                                            <th>Created</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {mails.map((mail, index) => (
                                            <tr key={mail.id}>
                                                <th scope="row">{index + 1}</th>
                                                <td onClick={() => copyToClipboard(`${mail.email}@${mail.domain}`)} style={{ cursor: 'pointer', color: 'blue' }}>
                                                    {`${mail.email}@${mail.domain}`}
                                                </td>
                                                <td onClick={() => copyToClipboard(mail.password)} style={{ cursor: 'pointer', color: 'blue' }}>
                                                    {mail.password}
                                                </td>
                                                <td>{mail.domain}</td>
                                                <td>{mail.quota} MB</td>
                                                <td>{new Date(mail.created).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric", hour12: true })}</td>
                                                <td>
                                                    <button type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Action</button>
                                                    <div className="dropdown-menu">
                                                        <button className="dropdown-item" data-bs-toggle="modal" data-bs-target={`#edit${mail.id}`}>Edit</button>
                                                        <button className="dropdown-item" onClick={() => {
                                                            Swal.fire({
                                                                title: 'Warning!',
                                                                text: "Are you sure you want to delete this E-Mail?",
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#3085d6',
                                                                cancelButtonColor: '#d33',
                                                                cancelButtonText: 'No, cancel!',
                                                                confirmButtonText: 'Yes, delete it!'
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    toast.info('Deleting E-Mail...');
                                                                    axios.delete(`/mail/${mail.email}`).then(res => {
                                                                        if (res.data === 'done') {
                                                                            GetMails();
                                                                            toast.success('E-Mail Deleted');
                                                                        } else {
                                                                            toast.error(res.data);
                                                                        }
                                                                    })
                                                                }
                                                            })
                                                        }} >Delete</button>
                                                        <a className="dropdown-item" href="https://hazho.com:2096/" target="_blank">Login</a>
                                                    </div>
                                                </td>
                                                <div className="modal fade" id={`edit${mail.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title"> Modify Account</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                            </div>
                                                            <form className="form-sample" onSubmit={(e) => {
                                                                e.preventDefault();
                                                                toast.info('Modifying Account...');
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                axios.put(`/mail/${mail.id}`, data).then(res => {
                                                                    if (res.data !== 'done') {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        toast.success('Account Modified Successfully');
                                                                        GetMails();
                                                                        e.target.reset();
                                                                        window.$(`#edit${mail.id}`).modal('hide');
                                                                    }
                                                                });
                                                            }}>
                                                                <div className="modal-body">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="email" className="form-label">E-mail</label>
                                                                        <input type="email" className="form-control" defaultValue={mail.email} placeholder="E-mail" disabled required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="password" className="form-label">Password</label>
                                                                        <input type="text" className="form-control" id="password" name="password" defaultValue={mail.password} placeholder="Password" required />
                                                                        <button type="button" className="btn btn-warning mt-2" onClick={() => { document.getElementById('password').value = generator(); }}>Generate Password</button>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                                    <button type="submit" className="btn btn-primary"> Update</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default List;
