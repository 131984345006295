import axios from 'axios';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';

const Bulk = () => {
    const navigate = useNavigate();
    return (
        <div className="single">
            <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-header d-sm-flex d-block border-0 pb-3">
                        <h5 className="fs-20 text-black">Create Bulk E-mail</h5>
                    </div>
                    <div className="card-body">
                        <div className="alert alert-warning alert-dismissible fade show" role="alert">
                            <strong>Warning!</strong>Unfortunatly this feature is not available right now, Becuase you used all your free quota. Please upgrade your account to use this feature.
                        </div>
                        <form id="newForm" onSubmit={(e) => {
                            toast.info("Auto Generation started, Please Wait...")
                            e.preventDefault();
                            const formData = new FormData(e.target);
                            const data = Object.fromEntries(formData.entries());
                            $('#newForm').hide();
                            axios.post("/bulk", data).then((res) => {
                                if (res.data !== "done") {
                                    toast.error(res.data)
                                } else {
                                    $('#newForm').show();
                                    toast.success("Created Successfully")
                                    e.target.reset();
                                    navigate('/');
                                }
                            })
                        }}>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Enter Count</label>
                                <input type="number" className="form-control" name="count" placeholder="How Many Email You want to Generate ?" required />
                                <small className="text-muted">Note: This will generate random email and password</small>
                            </div>
                            <div className="mb-3">
                                <button type="submit" className="btn btn-primary me-2">Start </button>
                                <button className="btn btn-secondary" onClick={() => { document.getElementById('newForm').reset(); }}>Clear</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Bulk;